<!--
 * @Author: DY
 * @Date: 2020-07-08 10:43:09
 * @LastEditTime: 2020-07-09 15:12:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_dev\src\views\contractControl\earlyWarning.vue
-->
<template>
    <div style="background:#000;">
        <div class="earlyWarning">
            <div class="earlyWarningContant">
                <div class="earlyWarningInfo">
                    <dl>
                        <dt>预警信息</dt>
                        <dd>
                            <p>预警时间：2020-6-26 00:00:00</p>
                            <p>结算期次：第13期</p>
                            <p>结算时间：2020-6-15</p>
                            <p>付款截止时间：2020-6-25</p>
                        </dd>
                    </dl>
                    <ul>
                        <li>
                            <h5>本期应收(万元)</h5>
                            <p>142.86</p>
                        </li>
                        <li>
                            <h5>本期实收(万元)</h5>
                            <p>142.86</p>
                        </li>
                        <li>
                            <h5>本期欠款(万元)</h5>
                            <p>142.86</p>
                        </li>
                        <li>
                            <h5>授信金额(万元)</h5>
                            <p>142.86</p>
                        </li>
                        <li>
                            <h5>差值(万元)</h5>
                            <p>142.86</p>
                        </li>
                        <li>
                            <h5>预警金额(万元)</h5>
                            <p>142.86</p>
                        </li>
                    </ul>
                </div>
                <div class="earlyWarningReason">
                    <h3>
                        <i class="iconfont iconbianji1"></i>
                        预警原因描述：
                    </h3>
                    <p>上一个结算期应收142.86万元，实收125万元，本期欠款17.86万元，比最大授信金额20万元仅差2.14万元，已经低于预警金额4万元，所以预警提醒。</p>
                </div>
                <footer>
                    <button @click="Close">
                        关闭
                    </button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 预警提醒
    name: 'early-warning',
    components: {},
    props: {},
    data() {
        return {
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {
        Close() {
            this.$parent.hide();
        },
    },
};
</script>
<style lang="stylus" scoped>
.earlyWarning
    display inline-block
    width:auto;
    height:6.6rem;
    padding 0.1rem
    position relative
    background: rgba(255,255,255,0.5);
    .earlyWarningContant
        width 100%
        height 100%
        background #fff
        .earlyWarningInfo
            border-bottom 1px solid #F0F0F0
            padding 0.4rem 0.4rem 0.1rem 0.4rem
            dl
                dt
                    font-size 0.2rem
                    color #333
                dd
                    overflow hidden
                    margin 0.2rem 0
                    p
                        float left
                        font-size 0.16rem
                        line-height 0.4rem
                        background #F5F6F8
                        color #333
                        padding 0 0.1rem
                        margin-right 0.1rem
                        &:last-child
                            margin-right 0
            ul
                overflow hidden
                li
                    float left
                    width 1.2rem
                    height 1.2rem
                    text-align center
                    margin-right 0.2rem
                    margin-bottom 0.1rem
                    background rgba(85,136,241,0.1);
                    h5
                        font-size 0.14rem
                        color #333
                        margin 0.3rem 0 0.15rem 0
                    p
                        font-family technology
                        font-size 0.28rem
                        color #5588F1
                    &:nth-of-type(4)
                        background rgba(33,189,204, 0.1);
                        p
                            color #21BDCC
                    &:nth-of-type(5)
                        background rgba(250,132,54, 0.1);
                        p
                            color #FA8436
                    &:last-child
                        margin-right 0
                        background rgba(235,101,111, 0.1)
                        p
                            color #EB656F

        .earlyWarningReason
            padding 0.1rem 0.4rem 0.4rem 0.4rem
            h3
                width 1.72rem
                height 0.42rem
                border 1px solid #D6000F
                color #D6000F
                font-size 0.18rem
                line-height 0.4rem
                text-align center
                margin 0.1rem 0
            p
                width 8.2rem
                font-size 0.16rem
                line-height 0.36rem
                color #333
        footer
            text-align center
            position absolute
            bottom 0.3rem
            left 0
            width 100%
            button
                border 2px solid #1577D2
                background #fff
                width 1.84rem
                height 0.44rem
                font-size 0.2rem
                color #1577D2
                cursor pointer
</style>